
if (document.querySelector('#password')) {

        document.getElementById("btn-submit").addEventListener("click", function (event) {
            let password = document.getElementById('password').value;
            let confirm_password = document.getElementById('confirm_password').value;
            let display = document.getElementById('display');
            if (password != confirm_password) {
                event.preventDefault();
                event.stopPropagation();
                display.classList.add('alert', 'alert-danger', 'font-weight-bold');
                display.innerHTML = "Le due password non coincidono";
                event.preventDefault();
                return;
            }

            if (!password.match(/[a-z]+/)) {
                display.classList.add('alert', 'alert-danger', 'font-weight-bold');
                display.innerHTML = "La password deve contenere almeno un carattere minuscolo";
                event.preventDefault();
                return;

            }
            if (!password.match(/[A-Z]+/)) {
                display.classList.add('alert', 'alert-danger', 'font-weight-bold');
                display.innerHTML = "La password deve contenere almeno un carattere maiuscolo";
                event.preventDefault();
                return;

            }
            if (!password.match(/[0-9]+/)) {
                display.classList.add('alert', 'alert-danger', 'font-weight-bold');
                display.innerHTML = "La password deve contenere almeno un carattere numerico";
                event.preventDefault();
                return;

            }
            if (password.length < 8) {
                display.classList.add('alert', 'alert-danger', 'font-weight-bold');
                display.innerHTML = "La password deve contenere almeno 8 caratteri";
                event.preventDefault();
                return;

            }
        });
    }